<template>
    <div class="content-wizard">
        <Sidebar v-bind:register_id="this.$route.params.id" />
        <div class="col-wiz-r">
            <div class="d-block py-4 px-5">
                <div class="d-block mb-4">
                    <div class="d-block">
                        <h3 class="m-0">Deksripsi Formulir</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" v-if="fetchData">
                        <p>Sedang mengambil data...</p>
                    </div>

                    <div class="col-md-12" v-if="!fetchData">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex align-items-center mb-4 pb-4 border-bottom">
                                    <div class="icon-circle icon-circle-lg flex-shrink-0 mr-3">
                                        <span class="fe fe-book-open h1 m-0 text-muted"></span>
                                    </div>
                                    <div>
                                        <h2 class="m-0">{{formulirData.data.nama_formulir}}</h2>
                                    </div>
                                </div>

                                <div class="d-block" style="font-size: 1rem;">
                                    <div v-html="formulirData.data.deskripsi_formulir">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Sidebar from '@/components/siswa/Sidebar.vue'

    export default {
        name: 'DetailFormulir',
        components: {
            Sidebar
        },
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - Detail Formulir'
        },
        data() {
            return {
                search: "",
                pageOfItems: [],
                formulirData: [],
                fetchData: true,
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function () {
                this.fetchData = true;
                let uri = this.$apiconfig + 'website/registrasi/detail_registrasi';
                this.$http.get(uri, {
                    params: {
                        registrasi_id: this.$route.params.id
                    }
                }).then(res => {
                    let getResponse = res.data;

                    if (getResponse.status) {
                        this.formulirData = getResponse;
                    } else {
                        alert("Data tidak ditemukan");
                        this.$router.push('/calon-siswa/formulir');
                    }

                    this.fetchData = false;
                });
            },
        }
    }
</script>