<template>
    <div class="col-wiz-l">
        <div class="d-block p-4">
            <div v-if="sidebarData.status">
                <div class="card">
                    <div class="d-flex align-items-center justify-content-between p-3">
                        <div class="d-flex align-items-center">
                            <div class="icon-circle icon-circle-primary-light mr-3">
                                <span class="fe fe-user"></span>
                            </div>
                            <div>
                                <div class="d-block user-wizard-sidebar font-weight-bold" data-toggle="tooltip"
                                    data-placement="top">{{sidebarData.data.fullname}}</div>
                                <div class="text-sm user-wizard-sidebar" data-toggle="tooltip" data-placement="top"
                                    >{{sidebarData.data.email}}</div>
                            </div>
                        </div>
                        <div>
							<router-link to="/signout" class="btn btn-white btn-sm shadow-none">Keluar</router-link>
                        </div>
                    </div>
                </div>

                <div class="d-block mb-4 pt-3 border-top">
                    <div class="d-block mb-3 text-sm">Nomor Seleksi</div>
                    <div class="d-block pb-2 border-bottom font-weight-bold">{{sidebarData.data.kode_registrasi}}</div>
                </div>

                <div class="d-block mb-4 pt-3 border-top">
                        <div class="d-block mb-3 text-sm">Formulir</div>
                        <div class="d-block pb-2 border-bottom">
                            <div class="h3 font-weight-bold d-block mb-2">{{sidebarData.data.nama_formulir}}</div>
                            <router-link :to="'../../../../calon-siswa/formulir/detail/'+this.$route.params.id" class="text-sm font-weight-bold">Lihat deskripsi formulir</router-link>
                        </div>
                </div>

                <div class="d-block mb-4">
                    <div class="d-block mb-3 text-sm">Langkah pendaftaran formulir</div>
                    <div class="d-block">
                        <router-link v-for="(item, index) in sidebarData.data.list_step" :key="index" class="d-flex w-100 mb-3 text-dark" :to="'../../../../calon-siswa/formulir/'+item.url+'/'+sidebarData.data.registrasi_id+'/'+item.step_id" :disabled="item.status == 'lock'" :event="item.status == 'open' ? 'click' : ''">
                            <div class="mr-2 flex-shrink-0" v-bind:class = "step_register_id == item.step_id ?'':'text-muted'">{{index+1}}</div>
                            <div class="d-flex align-items-center justify-content-between flex-grow-1 pb-2 border-bottom"  v-bind:class = "step_register_id == item.step_id ?'border-primary':''">
                                <div v-bind:class = "step_register_id == item.step_id ?'font-weight-bold':'text-muted'">
                                    {{item.title}}
                                </div>
                                <div v-if="step_register_id == item.step_id && item.finished == '0'" class="fe fe-chevron-right"></div>
                                <div v-if="item.status == 'lock'" class="fe fe-lock"></div>

                                <div v-if="item.status == 'open' && item.finished == '1'">
                                    <div class="text-primary text-sm"><span class="fe fe-check-circle mr-2"></span>{{item.text_finished}}</div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>

            <router-link to="../../../../calon-siswa/formulir" class="btn btn-outline-primary btn-sm"><span
                    class="fe fe-user mr-2"></span>Ke Akun Saya</router-link>

            <div class="d-block p-3 bg-light rounded mt-4">
                <div class="d-block text-sm">
                    <div class="d-block font-weight-bold">Menemukan masalah?</div>
                    <div class="d-block">Hubungi tim handal kami <a href="#">disini</a></div>
                </div>
                <div class="d-block pt-4">
                    <div class="d-block text-sm text-muted">Scola 2021. All Right Reserved</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['register_id','step_register_id'],
        data() {
            return {
                search: "",
                pageOfItems: [],
                sidebarData: [],
                fetchSidebar: true,
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function () {
                this.fetchSidebar = true;
                let uri = this.$apiconfig + 'website/registrasi/detail_registrasi';
                this.$http.get(uri, {
                    params: {
                        registrasi_id: this.register_id
                    }
                }
                ).then(res => {
                    let getResponse = res.data;

                    if(getResponse.status) {
                        this.sidebarData = getResponse;
                    } else {
                        alert("Data tidak ditemukan");
                        this.$router.push('/calon-siswa/formulir');                  
                    }
                    
                    this.fetchSidebar = false;
                });
            },
        }
    }
</script>